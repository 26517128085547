import {
  Telegram,
  ArrowRightAlt,
  BackupTable,
  Tune,
  Assessment,
  PersonOutline,
  NavigateNext,
} from "@mui/icons-material";
import SettingsIcon from "../../assets/SettingsIcon";
import HelmaSvg from "../../assets/Helma";

export const items = [
  {
    key: 1,
    icon: <Telegram />,
    title: "Telegram Notifications",
    dropdown: [
      {
        key: 2,
        href: "/telegram-notifications",
        icon: <NavigateNext />,
        title: "Global Notifications",
      },
      {
        key: 3,
        href: "/player-telegram-notifications",
        icon: <NavigateNext />,
        title: "Player Notifications",
      },
    ],
  },
  {
    key: 4,
    icon: <BackupTable />,
    title: "Data Tables",
    dropdown: [
      {
        key: 5,
        href: "/accounts",
        icon: <NavigateNext />,
        title: "Players",
      },
      {
        key: 6,
        href: "/tables/deposits",
        icon: <NavigateNext />,
        title: "Deposits",
      },
      {
        key: 7,
        href: "/tables/sessions",
        icon: <NavigateNext />,
        title: "Sessions",
      },
      {
        key: "demo-sessions",
        href: "/tables/demo-sessions",
        icon: <NavigateNext />,
        title: "Demo Sessions",
      },
      {
        key: "crypto-free-addresses",
        href: "/tables/crypto-free-addresses",
        icon: <NavigateNext />,
        title: "Crypto Free Addresses",
      },
      {
        key: 8,
        href: "/tables/withdrawals",
        icon: <NavigateNext />,
        title: "Withdrawals",
      },
      {
        key: 9,
        href: "/tables/cashback",
        icon: <NavigateNext />,
        title: "Cashbacks",
      },
      {
        key: 10,
        href: "/tables/transactions",
        icon: <NavigateNext />,
        title: "Transactions",
      },
      {
        key: 11,
        href: "/tables/internal-transactions",
        icon: <NavigateNext />,
        title: "Internal Transactions",
      },
      {
        key: 12,
        href: "/tables/available-games",
        icon: <NavigateNext />,
        title: "All Available Games",
      },
      {
        key: 13,
        href: "/tables/wallets",
        icon: <NavigateNext />,
        title: "Wallets",
      },
      {
        key: 14,
        href: "/tables/transfer-rates",
        icon: <NavigateNext />,
        title: "Exchange Rates",
      },
      {
        key: "transfer-rates-fiat",
        href: "/tables/transfer-rates-fiat",
        icon: <NavigateNext />,
        title: "Exchange Rates Fiat",
      },
      {
        key: "unsufficient-fee",
        href: "/tables/unsufficient-fee",
        icon: <NavigateNext />,
        title: "Unsufficient Fee",
      },
      {
        key: "players-free-cash",
        href: "/tables/players-free-cash",
        icon: <NavigateNext />,
        title: "Players Free Cash",
      },
      {
        key: "players-free-cash-fiat",
        href: "/tables/players-free-cash-fiat",
        icon: <NavigateNext />,
        title: "Players Free Cash Fiat",
      },
      {
        key: "players-free-spins",
        href: "/tables/players-free-spins",
        icon: <NavigateNext />,
        title: "Players Free Spins",
      },
      {
        key: "players-real-value",
        href: "/tables/players-real-value",
        icon: <NavigateNext />,
        title: "Players Real Value",
      },
      {
        key: "players-bonuses",
        href: "/tables/players-bonuses",
        icon: <NavigateNext />,
        title: "Players Bonuses",
      },
      {
        key: "players-removed-wallets",
        href: "/tables/player-removed-wallets",
        icon: <NavigateNext />,
        title: "Players Removed Wallets",
      },
      {
        key: "invites-emails",
        href: "/tables/invites-emails",
        icon: <NavigateNext />,
        title: "Invites emails",
      },
      {
        key: "transfers-lbc",
        href: "/tables/transfers-lbc",
        icon: <NavigateNext />,
        title: "LBC Transfers",
      },
      {
        key: "logs",
        href: "/tables/logs",
        icon: <NavigateNext />,
        title: "Logs",
      },
      {
        key: "persistance-logs",
        href: "/tables/persistance-logs",
        icon: <NavigateNext />,
        title: "Persistance Logs",
      },
      {
        key: "cell-expert",
        href: "/tables/cell-expert",
        icon: <NavigateNext />,
        title: "Cell Expert",
      },
      {
        key: "crypto-data-transactions",
        href: "/tables/crypto-data-transactions",
        icon: <NavigateNext />,
        title: "Crypto Data Transactions",
      },
      {
        key: "players-adjustment-balance",
        href: "/tables/players-adjustment-balance",
        icon: <NavigateNext />,
        title: "Players Adjustment Balance",
      },
      {
        key: "player-ip",
        href: "/tables/player-ip",
        icon: <NavigateNext />,
        title: "Player IP",
      },
    ],
  },
  {
    key: 15,
    icon: <Tune />,
    title: "Management",
    dropdown: [
      {
        key: 14,
        href: "/tables/crypto-dashboard",
        icon: <NavigateNext />,
        title: "Crypto Dashboard",
      },
      {
        key: 15,
        href: "/tables/blocked-games",
        icon: <NavigateNext />,
        title: "Blocked Games",
      },
      {
        key: 16,
        href: "/tables/lbc-games-management",
        icon: <NavigateNext />,
        title: "LBC Games Management",
      },
      {
        key: "blocked-gamification-games",
        href: "/tables/blocked-gamification-games",
        icon: <NavigateNext />,
        title: "Blocked Gamification Games",
      },
      {
        key: 17,
        href: "/tables/blocked-countries",
        icon: <NavigateNext />,
        title: "Blocked Countries",
      },
      {
        key: 18,
        href: "/tables/blocked-providers",
        icon: <NavigateNext />,
        title: "Blocked Providers",
      },
      {
        key: "blocked-game-currencies",
        href: "/tables/blocked-game-currencies",
        icon: <NavigateNext />,
        title: "Blocked Game Currencies",
      },
      {
        key: "blocked-withdrawal-currencies",
        href: "/tables/blocked-withdrawal-currencies",
        icon: <NavigateNext />,
        title: "Blocked Withdrawal Currencies",
      },
      {
        key: "blocked-deposit-currencies",
        href: "/tables/blocked-deposit-currencies",
        icon: <NavigateNext />,
        title: "Blocked Deposit Currencies",
      },
      {
        key: 19,
        href: "/tables/kyc",
        icon: <NavigateNext />,
        title: "KYC",
      },
      {
        key: "transfers",
        href: "/tables/transfers",
        icon: <NavigateNext />,
        title: "Transfers",
      },
      {
        key: 20,
        href: "/tables/regions",
        icon: <NavigateNext />,
        title: "Regions",
      },
      {
        key: 21,
        href: "/tables/withdrawal-request",
        icon: <NavigateNext />,
        title: "Withdrawal Requests",
      },
      {
        key: 22,
        href: "/tables/images",
        icon: <NavigateNext />,
        title: "Images",
      },
      {
        key: 23,
        href: "/tables/rewards",
        icon: <NavigateNext />,
        title: "Rewards",
      },
      {
        key: "crm-players",
        href: "/tables/crm-players",
        icon: <NavigateNext />,
        title: "CRM Players",
      },
      {
        key: "transfer-leetcoins-level",
        href: "/tables/transfer-leetcoins-level",
        icon: <NavigateNext />,
        title: "Transfer Leetcoins Level",
      },
      {
        key: "chat-messages",
        href: "/tables/chat-messages",
        icon: <NavigateNext />,
        title: "Chat Messages",
      },
    ],
  },
  {
    key: 16,
    icon: <Assessment />,
    title: "Reports",
    dropdown: [
      {
        key: 1,
        href: "/report/player-report",
        icon: <NavigateNext />,
        title: "Player Report",
      },
      {
        key: 2,
        href: "/report/player-wallet-report",
        icon: <NavigateNext />,
        title: "Player Wallet Report",
      },
      {
        key: 3,
        href: "/report/aff-report",
        icon: <NavigateNext />,
        title: "Aff Report",
      },
      {
        key: 4,
        href: "/report/aff-wallet-report",
        icon: <NavigateNext />,
        title: "Aff Wallet Report",
      },
    ],
  },
];

export const admin = {
  icon: <SettingsIcon />,
  title: "Administrator Panel",
  role: "Admin",
  image: <HelmaSvg />,
};

export const subAdmin = [
  {
    key: 18,
    href: "/users",
    icon: <PersonOutline />,
    title: "Users",
  },
  // {
  //   href: "/graphs",
  //   icon: <GraphsIcon />,
  //   title: "Graphs",
  // },
];
