import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Button = styled.button({
  flexDirection: "row",
  alignItems: "center",
  borderRadius: 5,
  height: 60,
  display: "flex",
  padding: "1rem",
  margin: "30px 15px 15px 15px",
  gap: "2rem",
  color: colors.danger,
  border: `1px solid ${colors.danger}`,

  ":hover": {
    background: colors.background,
  },
});

export const Text = styled.h6({
  color: colors.danger,
});

export const IconContainer = styled.div({
  color: colors.danger,
});
