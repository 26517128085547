import React from "react";
import { TitleButton, Title, IconCircle } from "./style";

type SidebarTitleItemProps = {
  title: string;
  icon?: React.ReactNode;
};

const SidebarTitleItem = (props: SidebarTitleItemProps) => {
  const { title, icon } = props;
  return (
    <TitleButton>
      <IconCircle>{icon}</IconCircle>
      <Title>{title}</Title>
    </TitleButton>
  );
};

export default SidebarTitleItem;
