import React from "react";
import { Button, Text, IconContainer } from "./style";
import LogOutSvg from "../../../assets/LogOut";
import { colors } from "../../../styles/colors";

type SidebarBottomProps = {
  text: string;
  onClick?: () => void;
};

const SidebarBottom = (props: SidebarBottomProps) => {
  const { text, onClick } = props;
  return (
    <Button as={"button"} onClick={onClick}>
      <IconContainer>
        <LogOutSvg color={colors.danger} />
      </IconContainer>
      <Text>{text}</Text>
    </Button>
  );
};

export default SidebarBottom;
