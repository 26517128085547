import { Box, Button, Typography } from "@mui/material";
import Image from "next/image";
import { useRouter } from "next/router";
import Error from "../assets/Error.svg";

export const ErrorFallback = ({ error, resetErrorBoundary }: any) => {
  const router = useRouter();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: "#DEC2BA",
        height: "100vh",
        width: "100vw",
        justifyContent: "center",
      }}>
      <Typography component="h5" variant="h5" color="red" sx={{ m: 4 }}>
        Something went wrong
      </Typography>
      <Image src={Error} alt="Error" width={150} height={100} />
      <Typography component="h6" variant="h6" color="darkred" sx={{ m: 4 }}>
        {error.message}
      </Typography>
      <Button
        sx={{ m: 4 }}
        color={"secondary"}
        type="submit"
        variant={"outlined"}
        onClick={() => {
          resetErrorBoundary();
          router.push("/dashboard");
        }}>
        Return to dashboard
      </Button>
    </Box>
  );
};
