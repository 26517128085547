import Cookies from "js-cookie";

const get = () => {
  return Cookies.get("token");
};

const set = (token: string) => {
  Cookies.set("token", token);
};

const remove = () => {
  return Cookies.remove("token");
};

export const accessToken = {
  get,
  set,
  remove,
};
