import * as React from "react";

const ArrowDownSvg = ({ color = "white" }: any) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.43056 8.5119C4.70012 8.19741 5.1736 8.16099 5.48809 8.43056L12 14.0122L18.5119 8.43056C18.8264 8.16099 19.2999 8.19741 19.5694 8.51191C19.839 8.8264 19.8026 9.29987 19.4881 9.56944L12.4881 15.5694C12.2072 15.8102 11.7928 15.8102 11.5119 15.5694L4.5119 9.56944C4.19741 9.29987 4.16099 8.8264 4.43056 8.5119Z"
      fill={color}
    />
  </svg>
);

export default ArrowDownSvg;
