import React from "react";
import { Button, Container, UserName, Role, UserContainer, UserInfo, Line } from "./style";
import { Collapse } from "@mui/material";
import ArrowDown from "../../../assets/ArrowDown";
import ArrowUp from "../../../assets/ArrowUp";

type SidebarUserProps = {
  username: string;
  role: string;
  imageSrc: React.ReactNode;
  trigger: boolean;
  list: React.ReactNode;
  roleCondition: boolean;
  onClick?: () => void;
};

const SidebarUser = (props: SidebarUserProps) => {
  const { username, role, imageSrc, trigger, list, roleCondition, onClick } = props;
  return (
    <Container>
      <Button as={"button"} onClick={onClick}>
        <UserContainer>
          {imageSrc}
          <UserInfo>
            <UserName>{username}</UserName>
            <Role>{role}</Role>
          </UserInfo>
        </UserContainer>

        {roleCondition && (trigger ? <ArrowUp /> : <ArrowDown />)}
      </Button>
      <Line />
      {roleCondition && (
        <Collapse in={trigger} timeout="auto" unmountOnExit>
          {list}
        </Collapse>
      )}
    </Container>
  );
};

export default SidebarUser;
