import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box, Button, CssBaseline, Dialog, DialogContent } from "@mui/material";
import { styles } from "./style";
import { ErrorModalProps } from "./types";
import Image from "next/image";
import Error from "../../assets/Error.svg";
import _ from "lodash";
import { useRouter } from "next/router";

const ErrorModal = ({ statusCode, open, setOpen, message, errors }: ErrorModalProps) => {
  const router = useRouter();

  return (
    <>
      <CssBaseline />
      <Dialog fullWidth open={open} maxWidth="sm" sx={styles.Modal}>
        <DialogContent sx={styles.Error}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Image src={Error} alt="Error" />
            </Box>
            <Typography component="h5" variant="h5" color="#6e0d25" sx={{ mt: 4 }}>
              {message}
              {_.isEmpty(errors) == false &&
                errors?.map(error => (
                  <Typography component="h6" variant="h6" sx={{ mt: 4, color: "#f28500" }}>
                    {error}
                  </Typography>
                ))}
            </Typography>
            <Button
              sx={{ m: 4 }}
              color={"secondary"}
              type="submit"
              variant={"outlined"}
              onClick={() => {
                if (statusCode == 403) {
                  router.push("/dashboard");
                  setOpen(false);
                } else {
                  setOpen(false);
                }
              }}>
              Close
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ErrorModal;
