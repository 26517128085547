import { Fragment, useEffect, useState } from "react";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import { Box, Divider, Drawer, List, useMediaQuery } from "@mui/material";
import { NavItem } from "./nav-item";
import React from "react";
import { accessToken } from "../auth/auth";
import { useAppContext } from "../context/useAppContext";
import { colors } from "../styles/colors";
import SidebarLogo from "../components/sidebar/sidebar-logo";
import SidebarHamburger from "./sidebar/sidebar-hamburger";
import SidebarTitleItem from "./sidebar/sidebar-titleItem";
import SidebarUser from "../components/sidebar/sidebar-user";
import CasinoWheelSvg from "../assets/CasinoWheel";
import SidebarBottom from "./sidebar/sidebar-bottom";
import { admin, items, subAdmin } from "./sidebar/sidebar-data";
import LogoSvg from "../assets/Logo";
import { selectRoles, setRoles } from "../redux/reducers/configReducer";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

export const DashboardSidebar = (props: any) => {
  const [openLogsMenu, setOpenLogsMenu] = useState(false);
  const roles = useSelector(selectRoles);
  const dispatch = useDispatch();

  const handleOpenDrawer = () => {
    if (open) {
      onClose?.();
      // setOpenDrawer
    }
  };

  const context = useAppContext();

  const handleLogsClick = () => {
    setOpenLogsMenu(prev => !prev);
  };

  const [openEventsMenu, setOpenEventsMenu] = useState(false);

  const handleEventsClick = () => {
    setOpenEventsMenu(prev => !prev);
  };

  const [openAdminMenu, setOpenAdminMenu] = useState(false);

  const handleAdminClick = () => {
    setOpenAdminMenu(prev => !prev);
  };

  const [openDropdown, setOpenDropdown] = useState(null);

  const handleDropdownClick = (dropdownKey: any) => {
    if (openDropdown === dropdownKey) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(dropdownKey);
    }
  };

  const { open, onClose } = props;
  const router = useRouter();
  const lgUp = useMediaQuery("(min-width:1200px)");

  useEffect(
    () => {
      if (!router.isReady) {
        return;
      }

      if (open) {
        onClose?.();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router.asPath],
  );

  const content = (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100vh",
        }}>
        <div>
          <Box
            sx={{
              p: 2,
              mb: 2,
              mt: 2,
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
            }}>
            <SidebarLogo
              logo={<LogoSvg />}
              onClick={() => {
                router.push("/dashboard");
              }}
            />
            {!lgUp && <SidebarHamburger onClick={handleOpenDrawer} open={open} />}
          </Box>

          <Box sx={{ px: 2, gap: 2, display: "flex", flexDirection: "row" }}>
            <SidebarTitleItem title="Casino Backoffice" icon={<CasinoWheelSvg />} />
          </Box>

          {roles.includes("SuperAdministrator") && (
            <>
              <SidebarUser
                username={admin.title}
                imageSrc={admin.image}
                role={admin.role}
                roleCondition={roles.includes("SuperAdministrator")}
                onClick={handleAdminClick}
                trigger={openAdminMenu}
                list={
                  <List component="div" sx={{ marginLeft: 5 }}>
                    {subAdmin.map(item => (
                      <NavItem
                        key={item.key}
                        icon={item.icon}
                        href={item.href}
                        title={item.title}
                        itemBackground="rgba(255,255,255, 0.05)"
                      />
                    ))}
                  </List>
                }
              />
            </>
          )}
          <Divider
            sx={{
              borderColor: colors.textGrey,
              width: "70%",
              margin: "auto",
              mb: 3,
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
            }}>
            {items.map(item => (
              <NavItem
                key={item.key}
                icon={item.icon}
                noLink
                title={item.title}
                condition={item.hasOwnProperty("dropdown")}
                onClick={() => handleDropdownClick(item.key)}
                trigger={openDropdown === item.key}
                activeTextColor={openDropdown === item.key ? colors.text : colors.textGrey}
                itemBackground="rgba(255,255,255, 0.05)"
                list={
                  <List component="div">
                    {item.dropdown?.map(list => (
                      <NavItem
                        key={list.key}
                        icon={list.icon}
                        href={list.href}
                        title={list.title}
                        itemText={colors.text}
                        itemBackground="rgba(255,255,255, 0.05)"
                      />
                    ))}
                  </List>
                }
              />
            ))}
          </Box>
        </div>
        <SidebarBottom
          text="Log out"
          onClick={() => {
            accessToken.remove();
            dispatch(setRoles([]));
            router.push("/");
          }}
        />
      </Box>
    </Fragment>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: colors.cards,
            color: "#FFFFFF",
            width: 300,
            p: 1,
          },
        }}
        variant="permanent">
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: colors.cards,
          color: "#FFFFFF",
          width: "100%",
        },
      }}
      sx={{ zIndex: (theme: { zIndex: { appBar: number } }) => theme.zIndex.appBar + 100 }}
      variant="temporary">
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
