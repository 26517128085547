import { HamburgerMenu } from "./style";
import MenuIcon from "@mui/icons-material/Menu";

type SidebarHamburgerProps = {
  open?: boolean;
  onClose?: any;
};

type Props = {
  onClick?: () => void;
} & SidebarHamburgerProps;

const SidebarHamburger = (props: Props) => {
  const { onClick, ...rest } = props;
  return (
    <HamburgerMenu as={"button"} {...rest} onClick={onClick}>
      <MenuIcon />
    </HamburgerMenu>
  );
};

export default SidebarHamburger;
