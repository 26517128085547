import styled from "styled-components";
import { colors } from "../../../styles/colors";
import { useMediaQuery } from "@mui/material";

export const Container = styled.div({
  position: "relative",
  width: "100%",
});

export const Button = styled.button({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: 5,
  height: 60,
  display: "flex",
  color: colors.text,
  padding: "1rem",
  marginTop: "2rem",
  marginBottom: "1rem",
  gap: "1rem",
  width: "100%",
});

export const UserContainer = styled.div({
  display: "flex",
  flexDirection: "row",
});

export const UserName = styled.h4({
  color: colors.text,
});

export const Role = styled.p({
  color: colors.textGrey,
  fontSize: 12,
});

export const UserInfo = styled.div({
  display: "flex",
  flexDirection: "column",
  textAlign: "left",
  marginLeft: "1rem",
});

export const Line = styled.hr({
  margin: "auto",
  width: "70%",
  borderTop: `1px solid ${colors.textGrey}`,
});
