import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const TitleButton = styled.button({
  flexDirection: "row",
  alignItems: "center",
  position: "relative",
  borderRadius: 5,
  backgroundColor: colors.primaryBackground,
  height: 60,
  width: "110%",
  display: "flex",
  color: colors.background,
  marginLeft: -10,
  padding: 10,
  gap: "1rem",
});

export const Title = styled.h3({
  color: colors.primary,
});

export const IconCircle = styled.div({
  backgroundColor: colors.primary,
  height: 30,
  width: 30,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 50,
});
