import * as React from "react";
import Typography from "@mui/material/Typography";
import { CircularProgress, CssBaseline, Dialog, DialogContent } from "@mui/material";
import { styles } from "./style";
import { LoadingModalProps } from "./types";

const LoadingModal = (props: LoadingModalProps) => {
  return (
    <>
      <CssBaseline />
      <Dialog open={true} maxWidth="sm" sx={styles.Modal} PaperProps={{ sx: { backgroundColor: "transparent" } }}>
        <DialogContent sx={styles.Dialog}>
          <CircularProgress size="3rem" sx={{ m: "0.2rem", color: "white" }} />
          <Typography component="h6" variant="h6" color="white" sx={{ mt: 1 }}>
            {props.header}
          </Typography>
          {props.subHeader && (
            <Typography variant="body2" color="white" sx={{ mt: 2 }}>
              {props.subHeader}
            </Typography>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LoadingModal;
