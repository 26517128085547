import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const HamburgerMenu = styled.button({
  position: "relative",
  color: colors.text,
  backgroundColor: colors.background,
  padding: 5,
  borderRadius: "5px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
