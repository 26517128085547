import { Alert, AlertColor, Snackbar } from "@mui/material";
import React, { createContext, useContext } from "react";

type NotificationContextActions = {
  showNotification: (text: string, typeColor: AlertColor) => void;
};

export const NotificationContext = createContext({} as NotificationContextActions);

interface NotificationContextProviderProps {
  children: React.ReactNode;
}

const NotificationProvider: React.FC<NotificationContextProviderProps> = ({ children }) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");
  const [typeColor, setTypeColor] = React.useState<AlertColor>("info");

  const showNotification = (text: string, color: AlertColor) => {
    setMessage(text);
    setTypeColor(color);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTypeColor("info");
  };

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={typeColor}>
          {message}
        </Alert>
      </Snackbar>
      {children}
    </NotificationContext.Provider>
  );
};

const useGlobalNotification = (): NotificationContextActions => {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error("useSnackBar must be used within an SnackBarProvider");
  }

  return context;
};

export { NotificationProvider, useGlobalNotification };
