import * as React from "react";

const ArrowUpSvg = ({ color = "white" }: any) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.5119 8.43056C11.7928 8.18981 12.2072 8.18981 12.4881 8.43056L19.4881 14.4306C19.8026 14.7001 19.839 15.1736 19.5694 15.4881C19.2999 15.8026 18.8264 15.839 18.5119 15.5694L12 9.98781L5.48809 15.5694C5.1736 15.839 4.70012 15.8026 4.43056 15.4881C4.16099 15.1736 4.19741 14.7001 4.5119 14.4306L11.5119 8.43056Z"
      fill={color}
    />
  </svg>
);

export default ArrowUpSvg;
