import { Link } from "./style";

type SidebarLogoProps = {
  onClick?: () => void;
  logo: React.ReactNode;
};

const SidebarLogo = (props: SidebarLogoProps) => {
  const { logo, onClick } = props;

  return (
    <Link as={"button"} onClick={onClick}>
      {logo}
    </Link>
  );
};

export default SidebarLogo;
