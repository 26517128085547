import { combineReducers } from "@reduxjs/toolkit";
import configReducer from "./reducers/configReducer";

const rootReducer = combineReducers({
  config: configReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
