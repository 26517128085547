import React, { createContext, PropsWithChildren, useContext, useMemo, useState } from "react";
import LoadingModal from "../../../components/modals/loading-modal";

export interface LoaderContext {
  loading: number;
  setLoading: React.Dispatch<React.SetStateAction<number>>;
  isLoading: boolean;
}

const LoaderProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [loading, setLoading] = useState(0);

  const isLoading = useMemo(() => {
    return loading > 0;
  }, [loading, setLoading]);

  return (
    <LoaderContext.Provider
      value={{
        loading,
        setLoading,
        isLoading,
      }}>
      {isLoading && <LoadingModal header={"Loading"} />}
      {children}
    </LoaderContext.Provider>
  );
};

const LoaderContext = createContext<LoaderContext>({} as LoaderContext);

const useLoaderContext = (): LoaderContext => {
  const loaderContext = useContext<LoaderContext>(LoaderContext);
  if (loaderContext === undefined) {
    throw new Error("useLoaderContext must be used inside AppContext.Provider");
  }
  return loaderContext;
};

export { LoaderProvider, useLoaderContext };
