import "../styles/globals.css";
import type { AppProps } from "next/app";
import { AppContextProvider } from "../context/useAppContext";
import { useCallback, useState } from "react";
import { CacheProvider, ThemeProvider } from "@emotion/react";
import { Box, CssBaseline, Grid, Paper, responsiveFontSizes, styled, useMediaQuery } from "@mui/material";
import { theme } from "../theme";
import { DashboardSidebar } from "../components/dashboard-sidebar";
import { useRouter } from "next/router";
import { accessToken } from "../auth/auth";
import "react-json-view-lite/dist/index.css";
import { SWRConfig } from "swr";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "../components/error";
import { IError } from "../interfaces/error";
import _ from "lodash";
import ErrorModal from "../components/modals/error-modal";
import jwt_decode from "jwt-decode";
import { DashboardNavbar } from "../components/dashboard-navbar";
import { NotificationContext, NotificationProvider } from "../components/notifications/notification-provider";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import createEmotionCache from "../utils/functions/create-emotion-cache";
import { LoaderProvider } from "../utils/hooks/loader/loader-context";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "../redux/store";
import LoadingModal from "../components/modals/loading-modal";

const responsiveTheme = responsiveFontSizes(theme);

const DashboardLayoutRoot = styled("div")(({ theme }: any) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  paddingTop: 24,
  [theme.breakpoints.up("lg")]: {
    paddingLeft: 280,
  },
}));
const clientSideEmotionCache = createEmotionCache();

export default function App({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
}: AppProps & { emotionCache: any }) {
  const [token, setToken] = useState<string>(accessToken.get() ?? "");
  let rolesInit = [];
  if (_.isEmpty(accessToken.get()) == false) {
    var decodedHeader: string = (jwt_decode(accessToken.get() as string) as any).roles;
    rolesInit = decodedHeader?.split(";");
  }

  const [roles, setRoles] = useState<string[]>([]);
  const [error, setError] = useState<IError>();
  const [sidebar, setSidebar] = useState<boolean>(true);
  const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);
  const router = useRouter();
  const paths = ["/reset-password-email", "/reset-password", "/documentation", "/"];
  const lgDown = useMediaQuery("(max-width:1200px)");

  const closeSideBar = useCallback(() => {
    setSidebar(false);
  }, []);

  const openSideBar = useCallback(() => {
    setSidebar(true);
  }, []);
  return (
    <CacheProvider value={emotionCache}>
      <LoaderProvider>
        <NotificationProvider>
          <NotificationContext.Consumer>
            {({ showNotification }) => (
              <Provider store={store}>
                <PersistGate loading={<LoadingModal header={"Loading"} />} persistor={persistor}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <ThemeProvider theme={responsiveTheme}>
                      <CssBaseline />
                      <SWRConfig
                        value={{
                          shouldRetryOnError: false,
                          onError: (error: IError, key) => {
                            console.log("error", error);
                            if (error.status == 401) {
                              accessToken.remove();
                              router.push("/");
                              showNotification(error?.message ?? "Logged out", "error");
                            } else if (error.status == 403) {
                              router.push("/dashboard");
                              const error = {
                                title: "Access Forbidden",
                                status: 403,
                                message: "Access Forbidden",
                                errors: ["User does have enough right to access."],
                              };
                              setError(error);
                              setErrorModalOpen(true);
                            } else if (error.status == 422) {
                              setError(error);
                              setErrorModalOpen(true);
                            } else {
                              showNotification(error?.message ?? "Something went wrong", "error");
                            }
                          },
                        }}>
                        <ErrorBoundary FallbackComponent={ErrorFallback}>
                          <AppContextProvider context={{ roles, setRoles, token, setToken }}>
                            {_.isEmpty(error) == false && (
                              <ErrorModal
                                statusCode={error?.status as number}
                                open={errorModalOpen}
                                setOpen={setErrorModalOpen}
                                message={error?.message as string}
                                errors={error?.errors as string[]}
                              />
                            )}
                            {paths.includes(router.pathname) || _.isEmpty(token) ? (
                              <Component {...pageProps} />
                            ) : (
                              <DashboardLayoutRoot>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flex: "1 1 auto",
                                    flexDirection: "column",
                                    width: "100%",
                                  }}>
                                  {lgDown && <DashboardNavbar onSidebarOpen={openSideBar} />}
                                  <Grid sx={{ m: lgDown ? 0 : 4, mt: lgDown ? 4 : 1 }}>
                                    <Component {...pageProps} />
                                  </Grid>
                                  <DashboardSidebar open={sidebar} onClose={closeSideBar} />
                                </Box>
                              </DashboardLayoutRoot>
                            )}
                          </AppContextProvider>
                        </ErrorBoundary>
                      </SWRConfig>
                    </ThemeProvider>
                  </LocalizationProvider>
                </PersistGate>
              </Provider>
            )}
          </NotificationContext.Consumer>
        </NotificationProvider>
      </LoaderProvider>
    </CacheProvider>
  );
}
