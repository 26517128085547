import { useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { AppBar, Avatar, Box, IconButton, Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { UserCircle as UserCircleIcon } from "../icons/user-circle";
import { AccountPopover } from "./account-popover";
import { colors } from "../styles/colors";

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: "#161616",
  boxShadow: "0px 1px 20px rgba(100, 116, 139, 0.2)",
  borderBottom: "1px solid #DEC2BA",
}));

export const DashboardNavbar = (props: any) => {
  const { onSidebarOpen, ...other } = props;

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar
        disableGutters
        sx={{
          minHeight: 64,
          left: 0,
          px: 2,
        }}>
        <IconButton
          onClick={onSidebarOpen}
          sx={{
            display: {
              xs: "inline-flex",
              lg: "none",
            },
          }}>
          <MenuIcon sx={{ color: "white" }} fontSize="small" />
        </IconButton>
        <Box sx={{ flexGrow: 1 }} />
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarOpen: PropTypes.func,
};
