export const colors = {
  primary: "#D9BBB2",
  primaryBackground: "#D9BBB210",
  cards: "#1f1f1f",
  background: "#161616",
  text: "#ffffff",
  textGrey: "#757575",
  danger: "#DD8E8E",
  success: "#5ACD96",
  modalBg: "#262626",
};
