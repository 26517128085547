import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ConfigState {
  roles: string[];
}

const initialState: ConfigState = {
  roles: [],
};

const configSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setRoles(state, action: PayloadAction<string[]>) {
      state.roles = action.payload;
    },
  },
});

export const { setRoles } = configSlice.actions;

export const selectRoles = (state: { config: ConfigState }) => state.config.roles;
export default configSlice.reducer;
