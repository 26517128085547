import NextLink from "next/link";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import { Box, Button, ListItem, Typography, Collapse } from "@mui/material";
import ArrowDown from "../assets/ArrowDown";
import ArrowUp from "../assets/ArrowUp";
import { colors } from "../styles/colors";
import { useMemo } from "react";

type NavProps = {
  key: number | string;
  href?: string;
  icon: React.ReactNode;
  title: string;
  condition?: boolean;
  trigger?: boolean;
  onClick?: () => void;
  list?: React.ReactNode;
  itemBackground?: string;
  itemText?: string;
  noLink?: boolean;
  activeTextColor?: string;
};

export const NavItem = (props: NavProps) => {
  const {
    href,
    icon,
    title,
    condition,
    trigger,
    list,
    itemText,
    activeTextColor,
    itemBackground,
    onClick,
    noLink,
    ...others
  } = props;
  const router = useRouter();
  const active = href ? router.pathname === href : false;
  const activeText = active ? colors.text : colors.textGrey;

  const item = useMemo(
    () => (
      <ListItem
        disableGutters
        sx={{
          color: active ? colors.text : colors.textGrey,
          "&:hover": {
            "& .listText": {
              color: !active ? itemText : undefined,
            },
            "& .listIcon": {
              color: !active ? itemText : undefined,
            },
          },
          position: "relative",
          flexDirection: "column",
          display: "flex",
          borderRadius: 1,
        }}
        {...others}>
        <Button
          disableRipple
          onClick={onClick}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}>
          <Box
            sx={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
            }}>
            <Box sx={{ mr: 2, color: activeTextColor || activeText }} className="listIcon">
              {icon}
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Typography
                color={activeTextColor || activeText}
                variant="subtitle2"
                className="listText"
                textAlign="start">
                {title}
              </Typography>
            </Box>
          </Box>
          {condition &&
            (trigger ? <ArrowUp color={activeTextColor || colors.textGrey} /> : <ArrowDown color={activeText} />)}
        </Button>
        {condition && (
          <Collapse in={trigger} timeout="auto" unmountOnExit>
            {list}
          </Collapse>
        )}
      </ListItem>
    ),
    [active, activeText, condition, icon, itemBackground, list, onClick, others, title, trigger],
  );

  return noLink ? (
    item
  ) : (
    <NextLink href={href ?? ""} passHref>
      {item}
    </NextLink>
  );
};

NavItem.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
};
